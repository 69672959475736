<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 11:56:30 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 19:04:40
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Frame\ProFile\UserInfoCard.vue
-->
<template>
  <div class="vue-box">
    <el-tabs tab-position="left" type="border-card" style="height: 600px">
      <!-- 基础设置 -->
      <el-tab-pane label="基础设置">
        <div class="tab-pane-title">基础设置</div>

        <el-form style="width: 35%">
          <el-form-item label="昵称：">
            <el-input v-model="nickname"></el-input>
          </el-form-item>

          <el-form-item label="手机号码：">
            <el-input v-model="phone"></el-input>
          </el-form-item>

          <el-form-item label="邮箱：">
            <el-input v-model="email"></el-input>
          </el-form-item>

          <el-form-item label="个性签名：">
            <el-input v-model="sign"></el-input>
          </el-form-item>
        </el-form>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_UpdateAccountBasicsInfo()"
          >保存</el-button
        >
      </el-tab-pane>

      <!-- 头像设置 -->
      <el-tab-pane label="头像设置">
        <div class="tab-pane-title">头像设置</div>
        <el-upload
          class="avatar-uploader"
          action=""
          name="image_file"
          :show-file-list="false"
          :http-request="_AvatarFileUpload"
        >
          <el-avatar :src="avatar" class="avatar">
            <img :src="require('@/assets/avatar/avatar-load-fail.png')" />
          </el-avatar>
          <div class="avatar-uploader-icon">点击修改头像</div>
        </el-upload>
      </el-tab-pane>

      <!-- 安全设置 -->
      <el-tab-pane label="安全设置">
        <div class="tab-pane-title">安全设置</div>

        <div class="security-setting-box">
          <div class="security-setting-item">
            <div class="security-setting-item-left">
              <p class="security-setting-item-name">账户密码</p>
              <p class="security-setting-item-label">当前密码强度:--</p>
            </div>
            <div class="security-setting-item-right">
              <el-button
                type="text"
                style="padding-right: 15px"
                @click="_OpenUpdatePasswordModel()"
                >修改</el-button
              >
            </div>
          </div>

          <div class="security-setting-item">
            <div class="security-setting-item-left">
              <p class="security-setting-item-name">用户名</p>
              <p class="security-setting-item-label">
                当前用户名:{{ username }}
              </p>
            </div>
            <div class="security-setting-item-right">
              <el-button
                type="text"
                style="padding-right: 15px"
                @click="_OpenUpdateUsernameModel()"
                >修改</el-button
              >
            </div>
          </div>

          <div class="security-setting-item">
            <div class="security-setting-item-left">
              <p class="security-setting-item-name">MFA设备</p>
              <p class="security-setting-item-label">
                未绑定 MFA 设备，绑定后，可以进行二次确认
              </p>
            </div>
            <div class="security-setting-item-right">
              <el-button
                type="text"
                style="padding-right: 15px"
                @click="_BindingMfa()"
                >修改</el-button
              >
            </div>
          </div>

          <div class="security-setting-item">
            <div class="security-setting-item-left">
              <p class="security-setting-item-name">组织</p>
              <p class="security-setting-item-label">
                当前所属组织:{{ kor_name }}
              </p>
            </div>
            <div class="security-setting-item-right">
              <!-- <el-button type="text"
                           style="padding-right: 15px">修改</el-button> -->
            </div>
          </div>
        </div>
      </el-tab-pane>

      <!-- 个性化 -->
      <el-tab-pane label="个性化">
        <div class="tab-pane-title">个性化</div>
        功能未启用!
      </el-tab-pane>

      <!-- 账户绑定 -->
      <el-tab-pane label="账户绑定">
        <div class="tab-pane-title">账户绑定</div>
        功能未启用!
      </el-tab-pane>

      <!-- 消息通知 -->
      <el-tab-pane label="消息通知">
        <div class="tab-pane-title">消息通知</div>
        功能未启用!
      </el-tab-pane>
    </el-tabs>

    <!-- 用户名增改组件 -->
    <update-user-name ref="update-user-name"></update-user-name>

    <!-- 密码增改组件 -->
    <update-password ref="update-password"></update-password>
  </div>
</template>

<script>
import {
  getAccountInfoByUser,
  updateAccountBasicsInfo,
  updateMyAvatar,
} from "./api"; //页面专有接口
import { uploadImage } from "@/api/api"; //图像文件上传接口
import UpdatePassword from "./UpdatePassword";
import UpdateUserName from "./UpdateUserName.vue";
export default {
  name: "UserInfoCard",
  components: {
    UpdatePassword,
    UpdateUserName,
  },
  data() {
    return {
      user_id: "", //用户ID
      avatar: null, //头像
      email: "", //邮箱
      gen_time: "", //注册时间
      kor_name: "", //组织名称
      nickname: "", //昵称
      phone: "", //手机号码
      sign: "", //个签
      status: "", //状态
      username: "", //用户名
    };
  },
  methods: {
    //载入数据
    _Load() {
      this._GetMyAccountInfo(); //加载用户信息
    },

    //加载用户信息
    _GetMyAccountInfo() {
      getAccountInfoByUser()
        .then((res) => {
          this.avatar =
            process.env.VUE_APP_BASE_URL + "/resources/" + res.data.avatar;
          this.email = res.data.email;
          this.gen_time = res.data.gen_time;
          this.kor_name = res.data.kor_name;
          this.nickname = res.data.nickname;
          this.phone = res.data.phone;
          this.sign = res.data.sign;
          this.status = res.data.status;
          this.user_id = res.data.user_id;
          this.username = res.data.username;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改账号基础信息
    _UpdateAccountBasicsInfo() {
      const params = {
        nickname: this.nickname,
        phone: this.phone,
        email: this.email,
        sign: this.sign,
      };
      updateAccountBasicsInfo(params)
        .then((res) => {
          this.$store.commit("SET_NICKNAME", this.nickname); //用户昵称
          this.$store.commit("SET_SIGN", this.sign); //个签
          this.$utils.success(res.err_msg);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改头像
    _AvatarUpload(avatar_name) {
      console.log(avatar_name);
      updateMyAvatar({ avatar_name })
        .then((res) => {
          let avatar = "/resources/" + res.data;
          this.avatar = process.env.VUE_APP_BASE_URL + avatar;
          this.$store.commit("SET_AVATAR", avatar); //头像
          //修改缓存中的头像设置
          let userinfo = localStorage.getItem("userinfo");
          userinfo = JSON.parse(userinfo);
          userinfo.avatar = res.data;
          localStorage.setItem("userinfo", JSON.stringify(userinfo));
          this.$utils.success(res.err_msg);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg, 6);
        });
    },

    //上传头像
    _AvatarFileUpload(file) {
      let formData = new FormData(); // 声明一个FormData对象
      formData.append(file.filename, file.file, file.file.name);
      uploadImage(formData)
        .then((res) => {
          this._AvatarUpload(res.data); //调用方法修改头像
        })
        .catch((err) => {
          this.$utils.error(err.err_msg, 6);
        });
    },

    //打开密码修改组件
    _OpenUpdatePasswordModel() {
      this.$refs["update-password"].open();
    },

    //打开用户名修改组件
    _OpenUpdateUsernameModel() {
      this.$refs["update-user-name"].open();
    },

    //绑定MFA
    _BindingMfa() {
      console.log("绑定MFA");
    },
  },
  created() {
    this._Load();
  },
};
</script>

<style scoped>
/* 头像设置 */
.avatar {
  background-color: rgb(255, 255, 255);
}

/* 安全设置 */
.security-setting-box {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 255);
}

.security-setting-item {
  width: 100%;
  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
  border-bottom: solid 1px #ebebeb;
}

.security-setting-item-left {
  width: 50%;
  margin: 15px 0;
  text-align: left;
  display: inline-block;
}

.security-setting-item-name {
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 16px;
}

.security-setting-item-label {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 16px;
}

.security-setting-item-right {
  width: 50%;
  margin: 15px 0;
  text-align: right;
  display: inline-block;
}

/* 表格项标题 */
.tab-pane-title {
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  text-align: left;
  font-size: 22px;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader.el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 0;
  font-weight: 600;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.avatar-uploader-icon:hover {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.356);
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
/* 重写标签样式 */
.is-focus,
.is-left,
.is-active {
  border: none !important;
}

.el-tabs__header > .is-left {
  background-color: #ffffff;
  border-right: solid 1px #eeeeee !important;
}

.el-tabs__item {
  width: 220px;
  height: auto;
  text-align: left !important;
  padding: 8px;
  padding-left: 23px;
}

.el-tabs__item:not(.is-disabled):hover,
.is-active {
  color: rgb(24, 144, 255) !important;
}

.is-active {
  background-color: rgb(230, 247, 255) !important;
}

.el-tabs__content {
  padding-left: 27px !important;
}
</style>
